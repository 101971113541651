<template>
  <div class="about-us">
    <div id="Content">
      <div class="content-inner">
        <div class="row features" id="ticketing">
          <div class="col full center">
            <h2 class="buyer">
              TICKETING & MANAGEMENT FEATURES
            </h2>
          </div>
          <Card class="col half">
            <ul>
              <li v-for="(feature, index) in ticketing_management" :key="index">
                {{ feature }}
              </li>
            </ul>
          </Card>
          <Card class="col half">
            <ul>
              <li
                v-for="(feature, index) in ticketing_management2"
                :key="index"
              >
                {{ feature }}
              </li>
            </ul>
          </Card>
        </div>

        <div class="row">
          <div class="col flex center">
            <h2>🚨 COMPARE IF YOU DARE 🚨</h2>
          </div>
        </div>

        <div class="row calculator">
          <div class="col full">
            <h4>INCREDITS</h4>
          </div>
          <div class="col quarterx3">
            <Card>
              <h4>Calculator</h4>
              <div class="row">
                <div class="col half">
                  <FormulateInput
                    type="number"
                    label="Ticket Quantity"
                    v-model="creditTotals.ticketSales"
                    @input="incredits"
                  />
                </div>
                <div class="col quarter">
                  <FormulateInput
                    type="money"
                    label="Avg Ticket Price ($)"
                    v-model="creditTotals.ticketPrice"
                    @input="incredits"
                  />
                </div>

                <div class="col quarter">
                  <FormulateInput
                    type="money"
                    class="avg-fees"
                    label="Avg Fees ($)"
                    disabled
                    v-model="creditTotals.our_avg"
                  />
                </div>
              </div>
            </Card>
          </div>
          <div class="col quarter">
            <Card>
              <h4>Industry Avg 22%</h4>
              <div class="col full">
                <FormulateInput
                  label="Total Fees Paid ($)"
                  class="ind-avg"
                  type="money"
                  disabled
                  v-model="creditTotals.ind_avg"
                />
              </div>
            </Card>
          </div>
          <div class="divider"></div>
        </div>

        <div class="row calculator">
          <div class="col full">
            <h4>PAY AS YOU GO</h4>
          </div>

          <div class="col quarterx3">
            <Card>
              <h4>Calculator</h4>
              <div class="row">
                <div class="col half">
                  <FormulateInput
                    type="number"
                    label="Ticket Quantity"
                    v-model="paygTotals.ticketSales"
                    @input="payg"
                  />
                </div>
                <div class="col quarter">
                  <FormulateInput
                    type="money"
                    label="Avg Ticket Price ($)"
                    v-model="paygTotals.ticketPrice"
                    @input="payg"
                  />
                </div>

                <div class="col quarter">
                  <FormulateInput
                    class="avg-fees"
                    type="money"
                    label="Avg Fees ($)"
                    disabled
                    v-model="paygTotals.our_avg"
                  />
                </div>
              </div>
            </Card>
          </div>
          <div class="col quarter">
            <Card>
              <h4>Industry Avg 22%</h4>
              <div class="col full">
                <FormulateInput
                  label="Total Fees Paid ($)"
                  class="ind-avg"
                  type="money"
                  disabled
                  v-model="paygTotals.ind_avg"
                />
              </div>
            </Card>
          </div>
          <div class="divider"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      creditTotals: {
        our_avg: 0,
        ind_avg: 0,
        ticketSales: null,
        ticketPrice: 1,
        fixedFee: 1.0,
        processingPercent: 5
      },
      paygTotals: {
        our_avg: 0,
        ind_avg: 0,
        ticketSales: null,
        ticketPrice: 1.0,
        processingPercent: 10
      },
      industryCreditsTotal: 0,

      industryAvgPercent: 22,

      ticketing_management: [
        "International payment processing",
        "Public and private events",
        "In-person, virtual and hybrid event integration",
        "Unlimited events",
        "Planner can pass on or absorb fees",
        "Easy event creation",
        "Maps",
        "Localized events",
        "Comps (free) tickets and will call",
        "Clone event",
        "Ticket scanning app",
        "Ticket and product bundles",
        "Volume discounts",
        "Promoter dashboard",
        "Analytics",
        "Financials",
        "Events dashboard",
        "White labelling",
        "Brand dashboard",
        "Unlimited ticket tiers"
      ],
      ticketing_management2: [
        "General, dated, and date/time reservation tickets",
        "Tax fields",
        "Secure purchasing",
        "Print tickets",
        "Instant email",
        "Email automation",
        "Import email addresses",
        "Social sharing",
        "Promo codes & discounts",
        "Manage email errors",
        "Social discounts",
        "Custom URLs",
        "User permissions",
        "API access",
        "Language and currency views",
        "Retention of all buyer data",
        "Dedicated support",
        "Referrals dashboard",
        "3rd party processors",
        "Attendee information collection",
        "& MORE FEATURES RELEASED REGULARLY!"
      ]
    };
  },
  methods: {
    incredits() {
      let total_fees =
        this.creditTotals.fixedFee +
        (this.creditTotals.processingPercent / 100) *
          (parseFloat(this.creditTotals.ticketPrice || 0) +
            this.creditTotals.fixedFee);

      let avg_total_fees =
        (this.industryAvgPercent / 100) *
        parseFloat(this.creditTotals.ticketPrice || 0);

      let credits_us = (
        parseInt(this.creditTotals.ticketSales || 0) * total_fees
      ).toFixed(2);
      this.$set(this.creditTotals, "our_avg", credits_us);

      let industryCreditsTotal = (
        parseInt(this.creditTotals.ticketSales || 0) * avg_total_fees
      ).toFixed(2);

      this.$set(this.creditTotals, "ind_avg", industryCreditsTotal);

      this.$forceUpdate();
    },
    payg() {
      let total_fees =
        (this.paygTotals.processingPercent / 100) *
        parseFloat(this.paygTotals.ticketPrice || 0);

      let avg_total_fees =
        (this.industryAvgPercent / 100) *
        parseFloat(this.paygTotals.ticketPrice || 0);

      let credits_us = (
        parseInt(this.paygTotals.ticketSales || 0) * total_fees
      ).toFixed(2);
      this.$set(this.paygTotals, "our_avg", credits_us);

      let industryCreditsTotal = (
        parseInt(this.paygTotals.ticketSales || 0) * avg_total_fees
      ).toFixed(2);

      this.$set(this.paygTotals, "ind_avg", industryCreditsTotal);

      this.$forceUpdate();
    }
  }
};
</script>

<style lang="less" scoped>
.content-inner {
  padding: 0 !important;
  h1 {
    font-size: 24px;
    color: white;
  }

  h2 {
    margin: 32px 0;
  }
  h1,
  h2 {
    color: white;
  }

  h3,
  h4 {
    color: var(--primary-green);
  }

  iframe {
    border-radius: 10px;
  }

  .row.header {
    h1 {
      margin-bottom: 0;

      &.incredible {
        background: linear-gradient(
          270deg,
          rgba(68, 182, 120, 1) 0%,
          rgba(43, 172, 217, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
        margin-bottom: 20px;
        font-size: 32px;
      }
    }
  }

  .footer-text {
    p {
      font-size: 20px;
      text-align: center;
      span {
        font-weight: bold;
        background: linear-gradient(
          270deg,
          rgba(68, 182, 120, 1) 0%,
          rgba(43, 172, 217, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .row.features-link {
    margin: 16px 0 !important;

    h2 {
      margin: 8px 0;
      cursor: pointer;
    }
  }
  .row.features {
    h2 {
      margin: 0;
      margin-bottom: 32px;
    }
    ul {
      padding-inline-start: 24px;
    }
    .card {
      width: 100%;
      padding: 16px !important;
    }
  }
  .row.calculator {
    h4 {
      margin: 16px 0;
      margin-top: 0;
    }
  }

  .formulate-input.avg-fees {
    ::v-deep input:disabled {
      opacity: 0.8;
    }
    ::v-deep input {
      font-weight: bold;
      color: var(--primary-green);
    }
  }

  .formulate-input.ind-avg {
    ::v-deep input:disabled {
      opacity: 0.8;
    }
    ::v-deep input {
      font-weight: bold;
      color: red;
    }
  }

  .row.divider-text {
    margin: 16px;
  }

  h2 {
    color: #ffffff;
    text-align: center;
  }

  .individuals-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.pricing-plan-container {
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  padding: 0 0 8px 0;
  margin: auto;
  display: flex;
  column-gap: 24px;
  row-gap: 24px;
  flex-wrap: wrap;

  .most-popular {
    background: var(--primary-green);
    padding: 1px;
    border-radius: 8px;

    > p {
      margin: 8px 0;
      font-size: 24px;
      text-align: center;
      color: black;
      font-weight: bold;
    }
    .plan {
      background: #2a2d33;

      ::v-deep .formulate-input {
        button {
          background: white;
        }
      }
    }
  }
}

.row.contact-us {
  .formulate-input {
    width: fit-content;
  }
}

@media screen and (max-width: 600px) {
  h2 {
    font-size: 22px;
  }
  .row {
    .col:not(:first-child) {
      &.half,
      &.quarter,
      &.quarterx3 {
        margin: 16px 0;
      }
    }
    .col.half,
    .col.quarter,
    .col.quarterx3 {
      width: 100% !important;
    }
  }
}
</style>
